const RULE_TYPE_NUMBER = 'number'
const RULE_TYPE_TRIGGER = 'trigger'
const RULE_TYPE_TIME = 'time'
const TIME_STEP = 5000

export {
    RULE_TYPE_NUMBER,
    RULE_TYPE_TRIGGER,
    RULE_TYPE_TIME,
    TIME_STEP
}