import { options as opts } from '../options'
import { createTemplate } from './template'

import {
  setStorage,
  isPromoShowed,
  markPromoShowed
} from '../storage'

let isPopupOpen = false
let previousFocus = null

function closeModal($modal, state, isCancel) {
  $modal.remove()
  isPopupOpen = false

  if (opts.onPopupClose) {
    opts.onPopupClose($modal, state.rule, isCancel)
  }

  document.activeElement.blur()

  if (previousFocus) {
    previousFocus.focus()
  }

  state.notify('close', $modal, state.rule, isCancel)
}

function submitForm($modal, storage, state) {
  let payload = {
    name: $modal.querySelector('input[name=name]').value,
    email: $modal.querySelector('input[name=email]').value,
    phone: !opts.hidePhoneField ? $modal.querySelector('input[name=phone]').value : null,
    extra_fields: [],
    source_name: state.rule.name,
    source_app: opts.sourceApp,
    referrer: document.referrer
  }

  const extraFields = state.rule.extraFields || opts.extraFields

  if (extraFields) {
    payload.extra_fields = extraFields.map(function(field) {
      let fVal

      if (field.type === 'dropdown'){
        fVal = $modal.querySelector(`.sbnc-form-${field.name} select`).value
      }
      else if (field.type === 'checkbox') {
        fVal = $modal.querySelector(`.sbnc-form-${field.name} input`).checked
      }
      else {
        fVal = $modal.querySelector(`.sbnc-form-${field.name} input`).value
      }

      return {
          name: field.name,
          label: field.label,
          value: fVal
      }
    })
  }

  var $button = $modal.querySelector('button')
  $button.disabled = true

  fetch(opts.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(payload)
  }).then(response => {
    if (response.ok) {
      storage.dateCaptured = (new Date()).getTime()
      setStorage(opts.storageKey, storage)

      if (opts.onSubmit) {
        opts.onSubmit(payload, state.rule)
      }

      state.notify('submit', payload)

      closeModal($modal, state)
    } else {
      $button.disabled = false

      if (opts.onError) {
        opts.onError(response.statusText, state.rule)

        state.notify('error', response.statusText)
      }
    }
  }).catch(error => {
    $button.disabled = false

    if (opts.onError) {
      opts.onError(error, state.rule)

      state.notify('error', error)
    }
  })
}

function maybeShowPromo(storage, state) {
  if (isPopupOpen || isPromoShowed(storage, state.rule)) {
    return null
  }
  
  if (state.rule.showURLs && state.rule.showURLs.length > 0) {
    let matchedObjectByUrl = state.rule.showURLs.find(item => item === location.href)
    if (matchedObjectByUrl === undefined) {
      return null
    }
  }
  
  setTimeout(function() {
    showPromo(storage, state)
  })

  return state
}

function showPromo(storage, state) {
  markPromoShowed(storage, state.rule)

  isPopupOpen = true

  let template = document.createElement('template')
  template.innerHTML = createTemplate(state, opts)

  let modalContainer = template.content.firstChild
  let modal = modalContainer.querySelector('.sbnc-modal')

  // Event handlers
  modal.querySelector('.sbnc-close').addEventListener('click', () => {
    closeModal(modalContainer, state, false)
    return false
  })

  modal.querySelector('.sbnc-cancel').addEventListener('click', () => {
    closeModal(modalContainer, state, true)
    return false
  })

  modal.addEventListener('click', (e) => {
    e.stopPropagation()
  })

  modalContainer.addEventListener('click', () => {
    closeModal(modalContainer, state, false)
    return false
  })

  modal.querySelector('.sbnc-form').addEventListener('submit', (e) => {
    e.preventDefault()

    submitForm(modalContainer, storage, state)

    return false
  })

  // Show popup
  document.querySelector('body').appendChild(modalContainer)

  if (opts.onPopupOpen) {
    opts.onPopupOpen(modal, state.rule);
  }

  previousFocus = document.activeElement

  state.notify('open', modal)
  modal.focus()

  return state
}

export {
  maybeShowPromo
}