let options = {
  url: null,
  storageKey: 'agile_promotions',
  sourceApp: null,
  // Rules
  /*
  {
    name: 'Sample rule',
    type: (one of) 'number', 'trigger',
    default: (number, optional)
    value: (number or string),
    // Setting overrides
    headerImage: null,
    headerImageAlt: null,
    headerText: 'Please enter your name!',
    formTitle: 'Tell us a little about yourself:',
    formButtonText: 'Give us your details',
    formCancelText: 'No, thanks',
    promoImage: null,
    promoImageAlt: null,
    promoHtml: null,
    hidePhoneField: false,
    extraFields: [
      {name: 'age', label: 'What's your age?', 'isRequired': true, 'type': 'dropdown', 'options': ['First', 'Second'],},
    ]
    // List of URLs on which to show the form => ["http://test.local.test:3000/", "http://test.local.test:3000/explore"]
    showURLs: [],
    // Show promo if user submitted one earlier
    showMultiple: true,
    promoImageAlt: null,
    promoHtml: null
  }
  */
  rules: [],
  // Events
  onPopupOpen: null,
  onPopupClose: null,
  onSubmit: null,
  onError: null,
  // Default settings
  timeExpiration: 60*60,
  submissionExpiration: 60*60*24*7,
  // Visual styles
  headerImage: null,
  headerImageAlt: null,
  headerText: 'Please enter your name!',
  formTitle: 'Tell us a little about yourself:',
  formButtonText: 'Give us your details',
  formCancelText: 'No, thanks',
  formLabels: {
    name: 'Name',
    email: 'Email',
    phone: 'Phone'
  },
  promoImage: null,
  promoImageAlt: null,
  promoHtml: null,
  extraLink: null,
  extraFields: null,
  hidePhoneField: false,
}

function updateOptions(opts) {
  let formLabels = Object.assign(options.formLabels, opts.formLabels)

  options = Object.assign(options, opts)
  options.formLabels = formLabels

  // Validate settings
  if (!options.url) {
    throw new Error('Please set promotion URL.')
  }
}

export {
  options,
  updateOptions
}