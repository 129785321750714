function createTemplate(state, opts) {
  // Header
  const headerImage = state.rule.headerImage || opts.headerImage
  const headerImageAlt = state.rule.headerImageAlt || opts.headerImageAlt
  const headerText = state.rule.headerText || opts.headerText

  // Content
  const promoImage = state.rule.promoImage || opts.promoImage
  const promoImageAlt = state.rule.promoImageAlt || opts.promoImageAlt
  const promoHtml = state.rule.promoHtml || opts.promoHtml
  const hasContent = promoImage || promoHtml
  const extraLink = state.rule.extraLink || opts.extraLink

  // Form
  const formTitle = state.rule.formTitle || opts.formTitle
  const hidePhoneField = state.rule.hidePhoneField || opts.hidePhoneField
  const extraFields = state.rule.extraFields || opts.extraFields
  const formLabels = state.rule.formLabels || opts.formLabels

  // Buttons
  const buttonText = state.rule.formButtonText || opts.formButtonText
  const cancelText = state.rule.formCancelText || opts.formCancelText

  return `<div class="sbnc-modal-container" role="dialog" aria-labelledby="sbnc-header-text" tabindex="-1">
    <div class="sbnc-modal" role="presentation" tabindex="-1">
      <header class="sbnc-header" aria-label="Popup header">
        ${headerImage ? `
        <img class="sbnc-header-image"
             src="${headerImage}"
             alt="${headerImageAlt}" />`:''}
        ${headerText ? `
        <span id="sbnc-header-text" class="sbnc-header-text" tabindex="-1">${headerText}</span>`:''}
        <a href="#" class="sbnc-close" role="button" aria-label="Close popup" tabindex="0">&times;</a>
      </header>
      ${hasContent ? `
      <main class="sbnc-content" aria-label="Popup content">
        ${promoHtml ? `
        <span tabindex="0" class="sbnc-text">${promoHtml}</span>`:''}
        ${promoImage ? `
        <img tabindex="0"
             class="sbnc-image"
             src="${promoImage}"
             alt="${promoImageAlt}"
        />`:''}
      </main>`:''}
      <form class="sbnc-form ${hasContent? 'sbnc-content-form' : 'sbnc-wide-form'}" aria-labelledby="sbnc-form-title-text">
        <legend class="sbnc-form-row sbnc-row-title" id="sbnc-form-title-text">${formTitle}</legend>
        <div class="sbnc-form-row sbnc-row-name" role="presentation">
          <input type="text" name="name" class="sbnc-form-control" placeholder="${formLabels.name}" aria-label="${formLabels.name}" required>
        </div>
        <div class="sbnc-form-row sbnc-row-email" role="presentation">
          <input type="email" name="email" class="sbnc-form-control" placeholder="${formLabels.email}" aria-label="${formLabels.email}" required>
        </div>
        ${!hidePhoneField ? `
        <div class="sbnc-form-row sbnc-row-phone" role="presentation">
          <input type="phone" name="phone" class="sbnc-form-control" placeholder="${formLabels.phone}" aria-label="${formLabels.phone}">
        </div>`:''}
        ${extraFields.map(field => `
          <div class="sbnc-form-row sbnc-form-${field.name} sbnc-form-${field.type ? field.type : 'text'}" ${field.hidden ? 'style="display: none"': ''} role="presentation">
            ${field.type === 'dropdown'? 
            `<select type="text" class="sbnc-form-control" ${field.isRequired ? 'required' : ''} aria-label="${field.label}">
              <option value="" disabled selected>${field.label}</option>
              ${field.options.map(option => `
              <option value="${option}">${option}</option>`
              ).join('')}
            </select>`
            : field.type === 'checkbox'? `
            <label>
              ${field.label}
              <input type="checkbox" class="sbnc-form-control" />
            </label>`
            :
            `<input type="text"
                    class="sbnc-form-control"
                    placeholder="${field.label}"
                    aria-label="${field.label}"
                    ${field.value ?
                      typeof field.value == 'function' ?
                        `value="${field.value()}"`
                        :   
                        `value="${field.value}"`
                      :
                      ''}
                    ${field.isRequired ? 'required' : ''} />`
            }
          </div>` 
        ).join('')}
        <div class="sbnc-form-row sbnc-row-submit" role="presentation">
          <button type="submit" class="sbnc-form-button sbnc-submit">
            ${buttonText}
          </button>
        </div>
        ${extraLink ? `
        <div class="sbnc-form-row sbnc-row-link" role="presentation">
          <a href="${extraLink.link}" class="sbnc-form-button-inverse sbnc-link">
            ${extraLink.text}
          </a>
        </div>`:''}
        <div class="sbnc-form-row sbnc-row-cancel" role="presentation">
          <a href="#" class="sbnc-form-button-inverse sbnc-cancel" role="button">
            ${cancelText}
          </a>
        </div>
        <div class="sbnc-form-row sbnc-row-privacy" role="presentation">
          <a href="https://studentbridge.com/privacy-policy"
            target="_blank"
            class="sbnc-privacy-link">
            Privacy Policy
          </a>
        </div>
      </form>
    </div>
  </div>`
}

export {
    createTemplate
}
