import { updateOptions } from './options'
import { initRules } from './rules'

function initPromo(opts) {
  // Options are imported by various other modules,
  // so need to be updated before everything
  updateOptions(opts)

  // Calling promotion modal display rules init,
  // which would create the form and return the api object
  let api = initRules()

  return api
}

export {
  initPromo
}