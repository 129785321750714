import { options as opts } from './options'

function getStorage(key) {
  let value = null

  try {
    value = JSON.parse(localStorage.getItem(key))
  } catch(e) {
    console.log('Failed to get and restore storage key', e)
  }

  return value
}

function setStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch(e) {
    console.log('Failed to set and stringify storage key', e)
  }

  return false
}

// Promotions
function getPromoKey(promotion) {
  return encodeURIComponent(promotion.name.replace(/ /g, '_'))
}

function isPromoShowed(storage, promotion) {
  // Do not show promo if user submitted one earlier
  if (!promotion.showMultiple && storage.dateCaptured && storage.dateCaptured + opts.submissionExpiration * 1000 > (new Date()).getTime()) {
    return true
  }

  let key = getPromoKey(promotion);
  let value = storage.showedPromotions[key]

  if (!value) {
    return false;
  }

  return value > (new Date()).getTime() - opts.timeExpiration * 1000;
}

function markPromoShowed(storage, promotion) {
  // Mark promotion as shown
  var key = getPromoKey(promotion);
  storage.showedPromotions[key] = (new Date()).getTime();

  // Save storage
  setStorage(opts.storageKey, storage);
}

export {
  getStorage,
  setStorage,
  getPromoKey,
  isPromoShowed,
  markPromoShowed
}