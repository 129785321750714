import * as consts from '../consts'
import { options as opts } from './options'
import { maybeShowPromo } from './form'
import {
  getStorage,
  setStorage,
  getPromoKey,
} from './storage'

function createState(rule) {
  let state = {
    rule: rule,
    events: {}
  }

  state.on = function(event, fn) {
    if (!state[event]) {
      state[event] = []
    }

    state[event].push(fn)

    return state;
  }

  state.notify = function(event) {
    if (state[event]) {
      let args = Array.prototype.slice.call(arguments, 1)

      for (let n in state[event]) {
        state[event][n].apply(state, args)
      }
    }

    return state
  }

  return state
}

function findPromo(name) {
  for (let i = 0; i < opts.rules.length; ++i) {
    if (opts.rules[i].name == name) {
      return opts.rules[i]
    }
  }

  return null
}

function initRules() {
  let storage = getStorage(opts.storageKey) || {};

  // Initialize tracked values and set defaults
  if (!storage.showedPromotions) {
    storage.showedPromotions = {}
  }

  if (!storage.trackedValues) {
    storage.trackedValues = {}
  }

  for (let i = 0; i < opts.rules.length; ++i) {
    let rule = opts.rules[i]

    let key = getPromoKey(rule)

    if (rule.type == consts.RULE_TYPE_NUMBER) {
      // Set default value for numbers
      if (typeof storage.trackedValues[key] == 'undefined') {
        storage.trackedValues[key] = rule.default || 0
      }
    } else if (rule.type == consts.RULE_TYPE_TIME) {
      // Set current date if uninitialized
      if (typeof storage.trackedValues[key] == 'undefined' || storage.trackedValues == null) {
        storage.trackedValues[key] = 0;
      }

      setInterval(() => {
        storage.trackedValues[key] += consts.TIME_STEP / 1000

        if (storage.trackedValues[key] >= rule.value) {
          storage.trackedValues[key] = 0

          let state = createState(rule)
          maybeShowPromo(storage, state)
        }

        setStorage(opts.storageKey, storage)
      }, consts.TIME_STEP)
    }
  }

  setStorage(opts.storageKey, storage)

  // Public API
  return {
    notify: function(name, value) {
      let rule = findPromo(name)
      if (rule) {
        let key = getPromoKey(rule)

        let state = createState(rule)

        if (rule.showURLs && rule.showURLs.length > 0) {
          let matchedObjectByUrl = rule.showURLs.find(item => item === location.href)
          if (matchedObjectByUrl === undefined) {
            return null
          }
        }

        if (rule.type == consts.RULE_TYPE_NUMBER) {
          storage.trackedValues[key] += value
          setStorage(opts.storageKey, storage)

          if (storage.trackedValues[key] >= rule.value) {
            storage.trackedValues[key] = rule.default || 0
            let result = maybeShowPromo(storage, state)
            setStorage(opts.storageKey, storage)
            return result
          }
        } else if (rule.type == consts.RULE_TYPE_TRIGGER) {
          if (rule.value == value) {
            return maybeShowPromo(storage, state)
          }
        }
      }

      return null
    }
  }
}

export {
  initRules
}